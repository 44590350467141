<template>
    <div class='page-content'>
        <div class="page-tips">
            {{ i18n.global.t('depositTitle1') }}
        </div>
        <div class="callBack-text">
            <div class="tips-header">{{ i18n.global.t('kycNameDesc') }}，{{ tips }}，</div>
            <div class="text-desc">
                {{ i18n.global.t('kycDesc') }}
            </div>
        </div>
        <div class="form-infos">
            <depositForm :status="1" :time="time" :ips="ip" :moneyNum="moneyNum" :mapAddress="mapAddress"></depositForm>
        </div>
    </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import i18n from "@/locales/index.js"
import depositForm from '@/components/depositForm'
const props = defineProps({
    tips: {
        type: String,
        default: () => 'Keven'
    },
    status: {
        type: Number,
        default: () => 1   // 1 您的存款正在处理中  2 is 您的存款已处理完成 3 is 您的提款正在处理中 4 is 您的提款已处理完成 5 is 异常登录
    }
})
const ip=ref('17.105.28.54')
const time=ref('30.07.2024 08:17')
const moneyNum=ref('4.00 USDT')
const mapAddress = ref('马来西亚 吉隆坡')
</script>

<style lang='scss' scoped>
.page-content {
    width: 100%;
    padding: 0 50px;
    box-sizing: border-box;

    .page-tips {
        color: #CFD2D9;
        font-size: 24px;
        padding: 8px 0;
        box-sizing: border-box;
    }
    .callBack-text {
        width: 100%;
        margin-top: 12px;
        text-align: left;

        .tips-header,
        .text-desc {
            color: #CFD2D9;
            font-size: 16px;
        }

        .text-desc {
            margin-top: 16px;
        }
    }
    .form-infos{
        width: 100%;
        margin-top: 20px;
    }
}
</style>
