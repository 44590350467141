<template>
    <div class="content-page">
        <img src="@/assets/logo.png" class="logo" alt="">
        <div v-if="pageType == 1" style="width: 100%;">
            <div class="page-slot">
                <!-- <lockout /> -->
                <activationEmail/>
            </div>
        </div>
        <div v-else>
            <!-- 激活邮箱 -->
            <div class="page-slot">
                <activationEmail/>
            </div>
            <!-- kyc认证 -->
            <div class="page-slot">
                <kycCertification/>
            </div>
            <!-- KYC认证 失败 成功-->
            <div class="page-slot">
                 <kycStatus :status="2"/>
            </div>
            <!-- 提款，登陆等 -->
            <div class="page-slot">
                <deposit />
            </div>
        </div>
        <div class="footer">
            <bottom-menu></bottom-menu>
        </div>
    </div>
</template>

<script setup>
import { ref,onMounted} from 'vue'
import i18n from "@/locales/index.js"
import bottomMenu from '@/components/bottomMenu'
import activationEmail from '@/components/activationEmail'
import kycCertification from '@/components/kycCertification'
import kycStatus from '@/components/kycStatus'
import deposit from '@/components/deposit'
import lockout from '@/components/lockout'
const pageType =ref(1)
onMounted(() => {

})

 
</script>

<style lang="scss" >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0 auto;

}
body{
    display: flex;
    align-items: center;
    justify-content: center;
}
body,ul,li{
    padding: 0;
    margin: 0;
  }
.content-page{
    width: 660px;
    height: 840px;
    background: #0C111C;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    .logo{
        width: 120px;
        height: 120px;
        margin-top: 24px;
        margin-bottom: 6px;
        overflow: hidden;
    }
    .page-slot{
        width: 100%;
    }
    .footer{
        width: 100%;
        position: absolute;
        bottom: 16px;
        left: 0;
    }
}
.textDecoration{
    text-decoration: underline;
    color: #00B780;
    cursor: pointer;
}
</style>
