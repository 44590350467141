const zh ={
    followUs:'关注我们',
    companyDesc:'252.com由YY Technology Co.B.V.所有和运营，YY Technology Co.B.V.是一家在库拉索商业登记处注册的公司，注册号：165699，注册地址：Curaçao SchottegatwegOost 10 Unit 1-9,Bon Bini Business Center。YY Technology Co.B.V.根据库拉索政府授权的Antilephone NV颁发的电子游戏许可证（编号8888/JAZ）运营。252.com以玩家为中心，满足世界各地玩家的需求，提供无限娱乐的赌博体验。',
    helpTips:'需要帮助?',
    platformService:'请联系客服',
    overActivation:'完成邮箱激活',
    overKYC:'完成KYC认证',
    helpBtn:'帮助中心',
    kycBtn:'立即KYC',
    kycSuccess:'认证成功!',
    kycError:'请重新提交您的文件以完成账户验证!',
    kycNameDesc:'您好',
    kycDesc:'感谢您通过完成我们的KYC验证流程来验证您的账户，我们想让您知道验证已成功。如果您对此验证有任何疑问，请访问帮助中心，或联系客服进行咨询',
    huryActivation:'立即激活',
    tipsEmailOne:'为了确保您可以安全地访问所有账户功能，请完成邮箱激活，点击下方按钮进行邮箱绑定激活',
    tipsEmailTwo:'252始终将用户账户安全放到首位。如需了解详情，敬请访问我们的',
    depositTitle1:"您的存款正在处理中",
    depositTip1:'感谢您的存款！请耐心等待，资金可能需要花费一些时间到账至您的252Casino账户中。',
    depositFormTips:'存款信息',
    depositFormText1:'时间',
    depositFormText2:'IP',
    depositFormText3:'金额',
    depositFormText4:'地点',
    depositFormAssets:'资产页面',
    tipLockout:'账号锁定',
}
export default zh