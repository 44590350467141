import { createI18n } from "vue-i18n";
import zh from "./lang/zh";
import en from "./lang/en";
export const getCurrLang =()=>{
    let sessionStorageLang =  sessionStorage.getItem('localeKey')
    return sessionStorageLang || 'zh';
}
const i18n = createI18n({
    legacy:false,
    locale:'zh',
    fallbackLocale:'zh',
    messages: {
        zh,
        en
    }
})
export default i18n